
export class UserDetails {
      accountId:string;
      userId: string;
      userName: string;
      accountManagerId=0;
      salesPersonName: string;
      accountName: string;
      accountTypeId: any;
      companyName: string;
      mobileNo: string;
      roleId: string;
      email: string;
      fullName: string;
      salesPersonMobile:string;
      salesPersonEmail:string;
      redirectUrl:string;
      statusText:string;
      parentId:string;
      address:string;
      createdOn:string;
      status:any;
      accountType:string;
      city:string;
      stateId:any;
      countryId:any;
      tarrifId:any;
      currencyId:any;
      gatewayId:any;
      smsrate:any;
      Token:any;
      isemailverify:any;
      isphoneverify:any;
      password:string;
      gatewayName:string;
      smsTypeId:number;
      isEncryption:boolean = false;
      logoImgPath: string;
      expiry:string;
      isCallBackUrl:boolean = false;
     
      // accountId: string;
    
      // businessLogo: string;
     
      // isDelivery: boolean;
     
      // kannelServerNameId: string;
    
      // smsRouteTypeId: string;
      // smsTransactionalRouteTypeId: string;
      
     // balance: string;
      constructor() {

      }
}

export class LiveMonth {
      liveMonth: String;
      constructor() {

      }
}

export class LiveYear {
      liveMonth: String;
      constructor() {

      }
}
